.prose h3 {
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgb(243 244 246);

    /* @apply mb-1 pb-2 border-b border-gray-200; */
  }

  .prose iframe {
    width: 100%;
    margin-top: 1.5rem;
    aspect-ratio: 16 / 9;
    border-radius: 0.75rem;

    /* @apply w-full aspect-video mt-6 rounded-xl; */
  }

  .swiper-button-prev{

    color: #0B8CAD;

  }

  .swiper-button-next{

    color: #0B8CAD;
    /* padding-left: 1200px; */


  }

  .swiper-pagination-bullet{


    
      display: none;



  }