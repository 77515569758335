@tailwind base;
@tailwind components;
@tailwind utilities;


@import "~react-image-gallery/styles/css/image-gallery.css";


html {
    --scrollbarBG: #fff;
    font-family: 'Lato', sans-serif;
  }
  body::-webkit-scrollbar {
    width: 14px;
    font-family: 'Lato', sans-serif;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
  





.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.swiper-wrapper{
  width: 100%;
}


